<template>
  <div class="business-reviewed-page-agree">
    <div class="searchForm">
      <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">
        <el-form-item label="商户名称" label-width="70px">
          <el-input type="text" class="inputStyle" v-model="form.shopName" maxlength="20" clearable placeholder="请输入商户名称"></el-input>
        </el-form-item>
        <el-form-item label="账户账号" label-width="70px">
          <el-input type="number" class="inputStyle" v-model="form.shopAccount" @input="inputPhone" clearable placeholder="请输入账户账号">
          </el-input>
        </el-form-item>
        <el-form-item label="审核状态" label-width="80px">
          <el-select v-model="form.applyState" placeholder="请选择" clearable>
            <el-option v-for="item in auditstatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
            <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
              <el-date-picker v-model="time" type="daterange" range-separator="到" start-placeholder="申请开始日期" end-placeholder="申请结束日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
            <el-button type="primary" class="sure" @click="screen">搜索</el-button>
            <el-button type="info" class="sure" @click="clear">清空</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="orderExplain flexbox flex-lr flex-align-center flex-justify-end"></div>
    <el-table :data="tableData" border>
      <el-table-column prop="joinId" label="序号" width="100"></el-table-column>
      <el-table-column prop="shopName" label="商户名称" min-width="140" show-overflow-tooltip></el-table-column>
      <el-table-column prop="shopAccount" label="商户账号" width="200"></el-table-column>
      <el-table-column prop="owner" label="归属代理" width="200"></el-table-column>
      <el-table-column prop="ownerTel" label="代理账号" width="200"></el-table-column>
      <el-table-column label="申请时间" width="200" prop="createTime">
        <template slot-scope="scope">
          <span>{{
            $formatDate(+scope.row.createTime * 1000, "yyyy-MM-dd HH:mm:ss")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="外卖/堂食商品" width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="productlist(scope.row.shopId)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="" label="团购商品" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="productcommodity(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column width="148" label="审核状态" prop="applyState">
        <template slot-scope="scope">
          <span>{{ scope.row.applyState == 1 ? "待审核" : "" }}</span>
          <span>{{ scope.row.applyState == 5 ? "已审核" : "" }}</span>
          <span v-if="scope.row.applyState != 5&&scope.row.applyState != 1">-</span>
        </template>
      </el-table-column>
      <el-table-column prop="profit" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click="checkskip(scope.row, '3')">{{ scope.row.applyState != 1 ? "重新审核" : "审核" }}
          </el-button>
          <el-button type="primary" plain size="mini" @click="checkskip(scope.row, '2')">编辑</el-button>
          <!-- 审核弹窗 1111-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  // $sp_shopAgree,
  // $sp_shopRefuse,
  $sg_merchantjoinList,
} from '@/api/business';
// import checkDetail from '@/components/common/checkDetail.vue';

export default {
  name: 'merchantToBeAudited',
  data() {
    return {
      saveValueArr: [], // 保存所选业务的值得数组
      supportBusiness: 3,
      businessShelveDialog: false, // 审核业务弹窗
      businessCheckList: [],
      businessList: [],
      list: [],
      trues: '',
      form: {
        applyState: '',
        joinId: '',
        shopAccount: '',
        shopName: '',
      },
      auditstatus: [
        { value: 1, label: '待审核' },
        { value: 5, label: '已审核' },
      ],
      time: [],
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      loading: false,
      textareas: '', // 驳回输入框
      innerVisible: false, // 嵌套通过弹窗
      innerbouhui: false, // 嵌套驳回弹窗
      checks: false, // 是否显示审核
      businessDetailDialog: false, // 是否显示详情弹窗
      shopName: '',
      xiangq: {},
    };
  },
  created() {
    this.shopInfoList();
    this.supportBusinessConfig = new this.$BinaryConfig('supportBusiness');
  },
  // components: {
  //   checkDetail,
  // },
  computed: {
    // dd() {
    //   return this.$store.getters['toaudit/editData'];
    // },
  },
  methods: {
    // 清空筛选条件
    clear() {
      this.form = {
        applyState: '',
        joinId: '',
        shopAccount: '',
        shopName: '',
      };
      this.time = '';
      this.shopInfoList();
    },
    productlist(id) {
      window.sessionStorage.setItem('shopId', id);
      this.$router.push({
        // name: 'commodityDetails',
        name: 'goodsList',
        query: {
          id,
          isEditable: 1,
        },
      });
    },
    // 团购商品
    productcommodity(data) {
      this.$router.push({
        name: 'groupManagementlist',
        query: {
          type: 1,
          shopId: data.shopId,
        },
      });
    },
    // 点击审核/编辑
    checkskip(data, type) {
      // type: 类型， 1-新增，2-编辑，3-审核
      this.$router.push({
        // name: 'commodityDetails',
        name: 'verificationMerchant',
        query: {
          row: JSON.stringify(data),
          type,
        },
      });
    },
    // 点击详情
    showDetail(item) {
      this.list = item;
      this.$refs.detailPage.detailVisible = true;
      this.checks = true;
      this.trues = false;
    },
    // 校验手机号-最多输入11位
    inputPhone(value) {
      if (value.length > 11) {
        this.form.shopAccount = value.slice(0, 11);
      }
    },
    // 驳回理由
    numLimit(value) {
      if (value.length > 100) {
        this.textareas = value.slice(0, 100);
      }
    },
    // 待审核列表
    shopInfoList() {
      const params = {
        current: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      params.applyStartTime = this.time[0];
      params.applyEndTime = this.time[1];
      $sg_merchantjoinList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 翻页
    pageChange() {
      this.shopInfoList();
    },
    // 筛选
    screen() {
      this.currentPage = 1;
      this.shopInfoList();
    },
  },
};
</script>

<style  lang="scss" scoped>
.pass {
  text-align: center;
  h4 {
    color: black;
  }
}
.weis {
  padding: 10px 50px;
  &::before {
    content: "";
    clear: both;
    height: 22px;
    width: 8px;
    display: inline-block;
    border-radius: 5px;
    background: red;
  }
  .el-button {
    width: 310px;
    height: 45px;
    font-size: 20px;
  }
}
.business-reviewed-page-agree {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;

  .cancel-btns {
    background-color: #909399;
    margin-left: 29px;
  }
  .affirm-btn {
    background-color: #ef3f46;
    margin: 50px 0 50px 10px;
    text-align: center;
  }
  .pass-btn {
    margin-right: 8%;
    background-color: #ef3f46;
  }
  .bohui-btn {
    background-color: #909399;
  }
  .cancel-btn {
    background-color: #909399;
    //  margin-left:29px
  }
  .submit-btn {
    display: block;
    margin: 6% 0 5% 30px;
    background-color: #ef3f46;
  }
  .pass {
    text-align: center;
    h4 {
      color: black;
    }
    .el-button {
      width: 320px;
      height: 45px;
      font-size: 20px;
    }
  }
  .el-textarea {
    width: 86%;
    margin: 5% 0 0 30px;
  }
  .dangerorinfo {
    margin-top: 5%;
    .el-button {
      width: 310px;
      height: 45px;
      font-size: 20px;
    }
  }
  .particularsbig {
    overflow: hidden;
    position: relative;
    .wei {
      padding: 10px 50px;
    }
    .off {
      cursor: pointer;
      font-size: 13px;
      border-radius: 20px;
      position: absolute;
      left: 992px;
      top: 40px;
      display: block;
      width: 150px;
      height: 43px;
      background-color: red;
      text-align: center;
      line-height: 43px;
      color: #fff;
      padding-right: 40px;
      box-sizing: border-box;
      z-index: 999;
    }
    img {
      width: 80px;
      height: 80px;
    }
    .el-row {
      margin-left: 10px;
      margin-top: 15px;
    }
    .grid-content {
      display: flex;
      align-items: center;
    }
    .red {
      color: red;
    }
  }
  .weis {
    &::before {
      content: "";
      clear: both;
      height: 22px;
      width: 8px;
      display: inline-block;
      border-radius: 5px;
      background: red;
    }
  }
  .wei {
    &::before {
      content: "";
      clear: both;
      height: 22px;
      width: 8px;
      display: inline-block;
      border-radius: 5px;
      background: red;
    }
    .el-button {
      width: 310px;
      height: 45px;
      font-size: 20px;
    }
  }
  .remark-title {
    display: inline-block;
    vertical-align: 3px;
    font-size: 22px;
    font-weight: bold;
    padding-left: 5px;
    color: black;
    height: 25px;
  }
  .remark-s {
    vertical-align: 3px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
    height: 25px;
  }
  .weiss {
    margin-top: 30px;
    margin-bottom: 30px;
    &::before {
      content: "";
      clear: both;
      height: 22px;
      width: 8px;
      display: inline-block;
      border-radius: 5px;
      background: red;
    }
    .remark-ss {
      vertical-align: 3px;
      font-size: 18px;
      font-weight: bold;
      padding-left: 5px;
      height: 25px;
    }
  }
  .rejectedstyle {
    width: 700px;
    height: 100px;
    font-size: 18px;
    line-height: 50px;
  }
  /deep/ .el-checkbox-group .el-checkbox .el-checkbox__label {
    font-size: 18px;
  }
  .el-form--inline .el-form-item {
    margin-right: 50px;
  }
  .inputStyle {
    width: 180px;
  }
  .sure {
    margin-left: 30px;
  }
  .orderExplain {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>
